import React from "react";
import Navbar from "../components/Navbar";

const World = () => {
  return (
    <div>
      <Navbar />
      World
    </div>
  );
};

export default World;
